<template>
  <div class="auth-form">
    <h2>Вхід</h2>
    <div class="auth-form-description mt-3 mb-6">
      Увійдіть щоб продовжити користуватись сайтом.
    </div>
    <v-form ref="form">
      <v-text-field
        class="mt-3 main-field"
        label="Електронна пошта*"
        variant="outlined"
        prepend-inner-icon="mdi-email-outline"
        v-model="data.email"
        :error-messages="errors.email"
        @input="errors.email = []"
      ></v-text-field>

      <v-text-field
        class="mt-3 main-field"
        label="Пароль*"
        variant="outlined"
        v-model="data.password"
        :type="passwordVisible ? 'text' : 'password'"
        :append-inner-icon="passwordVisible ? 'mdi-eye-off' : 'mdi-eye'"
        @click:append-inner="passwordVisible = !passwordVisible"
        :error-messages="errors.password"
        @input="errors.password = []"
      ></v-text-field>

      <div class="d-flex flex-column mt-8">
        <v-btn
          size="large"
          color="black"
          block
          @click="onSubmit"
          variant="flat"
          :disabled="authStore.loading"
        >
          Продовжити
        </v-btn>

        <div class="has-account mt-8">
          <nuxt-link to="/forgot-password">Забули пароль?</nuxt-link>
        </div>

        <div class="has-account mt-8">
          У мене немає облікового запису
          <nuxt-link to="/register">Створити обліковий запис</nuxt-link>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script setup lang="ts">
import { useUserModeStore } from "@/stores/useUserModeStore";
definePageMeta({
  middleware: "sanctum:guest",
});

const authStore = useAuthStore();
const userModeStore = useUserModeStore();
const passwordVisible = ref(false);

const data = ref({
  email: "seller1@test.com",
  password: "password",
});

const errors = ref({
  email: [],
  password: [],
});

const onSubmit = async () => {
  try {
    await authStore.login(data.value);
    userModeStore.mountUserMode();
  } catch (error: any) {
    if (error.response.status === 422) {
      errors.value = error.response._data.errors;
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/modules/auth.scss";
</style>
